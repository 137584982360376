'use client'
import { memo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { mq, vw, getP75, getP40, getP30, getMargin20 } from '@/styles'

export const Excavator404 = memo(() => {
  return (
    <Div>
      <Main>
        <ImageContainerEx>
          <img src='/images/404/excavadora.png' height='370' width='450'></img>
        </ImageContainerEx>
        <TextContainer>
          <h1>404</h1>
          <h2>
            Poof! <br />
            It&apos;s gone
          </h2>
          <p>
            This page is no longer here.<br />
            Or maybe you just need to refresh? <br />
            In any case you will be always welcome <Link href={'/'}>home</Link>.
          </p>
        </TextContainer>
      </Main>
    </Div>
  )
})

const Div = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  ${mq.greaterThan('tablet')} {
    height: 80vh;
  }
`

const Main = styled.div`
  display: flex;
  width: 99vw;
  height: 86vh;
  align-items: end;
  padding: 0 ${vw(5, 'mobile')} ${vw(40, 'mobile')};

  ${mq.greaterThan('tablet')} {
    height: 70vh;
    padding: 0 ${vw(10, 'desktop')} ${vw(40, 'desktop')} ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop-wide')} {
    height: 75vh;
    padding: 0;
  }

`
const TextContainer = styled.div`
  position: absolute;
  left: 0px;
  animation: movetoleft 6.5s linear 1;

  @keyframes movetoleft {
    0% {
      transform: translateX(810px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  ${mq.greaterThan('tablet')} {
      left: 130px;

      @keyframes movetoleft {
        0% {
          transform: translateX(2140px);
        }
       100% {
          transform: translateX(0px);
        }
      }
  }

  h1 {
    ${getP40()}
    text-align: left;
  }

  h2, p {
    text-align: left;
  }

  h2 {
    ${getMargin20()}
    ${getP75()}
  }

  p {
    ${getP30()}
  }

  a {
    text-decoration: underline;
  }
`

const ImageContainerEx = styled.div`
  position: absolute;
  left: -420px;
  height: 330px;
  display: flex;
  align-items: flex-end;
  animation: movetolefttruck 7s 0s linear 1;

  ${mq.greaterThan('tablet')} {
    height: 450px;
  }

  @keyframes movetolefttruck {
    0% {
      transform: translateX(810px);
    }
    100% {
      transform: translateX(-100px);
    }
  }

  ${mq.greaterThan('tablet')} {
    @keyframes movetolefttruck {
      0% {
        transform: translateX(2140px);
      }

      100% {
        transform: translateX(-100px);
      }
    }
  }

  img {
    height: 293px;
    ${mq.greaterThan('tablet')} {
      height: 370px;
    }
  }
`
